@media (max-width: 1100px) and (min-width: 992px) {
  .topNav{
    .topNav-logo{
      width: 160px !important;
    }
    .topNav-links{
      > a {
        font-size: 12px !important;
        padding: 0 32px !important;
        > i {
          display: none !important;
        }
      } 
    } 
    .myAccount-link{
      width: 32px !important;
      height: 32px !important; 
      > i {
        font-size: 16px !important;
      }
    }
  }
}

@media (max-width: 991px) and (min-width: 851px){
  .topNav{
    .topNav-logo{
      width: 160px !important;
    }
    .topNav-message {
      opacity: 0;
      visibility: hidden;
      transition: opacity 1s ease-in-out, visibility 0.5s ease-in-out;
    }
    .topNav-message.show {
      opacity: 1;
      visibility: visible;
      font-weight: 100;
      font-size: 12px;
      position: absolute;
      width: fit-content;
      background-color: white;
      padding: 5px;
      color: black;
      z-index: 1103;
      top: 50px;
    }
    .topNav-links-mobile{
      display: flex;
      flex-direction: row;
      > a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        margin-right: 20px;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        z-index: 1102;
        text-decoration: none;
        > i {
          display: inline-flex;
          margin: auto;
          color: black;
          text-decoration: none !important;
          z-index: -1;
        }
      } 
      > a:hover {
          > i{
            text-decoration: none !important;
            color: #de8314 !important;
          }
        } 
    } 
    
  }
  .home {
    .search {
      height: 333px !important;
      .search-title {
        font-size: 7vw;
      }
      .search-form {
        .search-form-label {
          font-size: 5vw;
        }
      }
    }
    .search-results-container {
      top: 400px !important;
    }
  }

  .vector-container{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.813);
    width: 40%;
    height: 64px !important;
    border-radius: 48px;
    bottom: 16px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    cursor: pointer;
    z-index: 1101;
    backdrop-filter: blur(8px);
    #vector-description{
      font-weight: 500 !important;
      padding: 0 0 0 36px;
      font-size: 16px;
    }
    #vector{
      width: 48px;
      height: 48px;
      margin-left: auto;
      margin-right: 32px;
    }
    #number{
      position: absolute;
      top: 0;
      right: 0;
      font-family: "Inter", sans-serif;
      border: 2px solid white;
      background-color: rgb(28, 116, 28);
      font-weight: 300;
      width: 20px;
      height: 20px;
      color: white;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    }
  }
  .vector-container:hover{
    -webkit-box-shadow:  0 3px 27px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 27px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 27px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  }
}

@media (max-width: 850px) {
  .topNav{
    .topNav-logo{
      width: 160px !important;
    }
    .topNav-message {
      opacity: 0;
      visibility: hidden;
      transition: opacity 1s ease-in-out, visibility 0.5s ease-in-out;
    }
    .topNav-message.show {
      opacity: 1;
      visibility: visible;
      font-weight: 100;
      font-size: 12px;
      position: absolute;
      width: fit-content;
      background-color: white;
      padding: 5px;
      color: black;
      z-index: 1103;
      top: 50px;
    }
    .topNav-links-mobile{
      display: flex;
      flex-direction: row;
      > a {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        text-align: center;
        display: flex;
        margin-right: 20px;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        z-index: 1102;
        text-decoration: none;
        > i {
          display: inline-flex;
          margin: auto;
          color: black;
          text-decoration: none !important;
          z-index: -1;
        }
      } 
      > a:hover {
          > i{
            text-decoration: none !important;
            color: #de8314 !important;
          }
        }  
    } 
  }
  .home {
    .search {
      height: 333px;
      .search-title {
        font-size: 7vw;
      }
      .search-form {
        .search-form-label {
          font-size: 5vw;
        }
      }
    }
    .search-results-container {
      top: 386px !important;
    }
  }
  .vector-container{
    position: fixed;
    background-color: rgba(255, 255, 255, 0.813);
    width: 40%;
    height: 64px !important;
    border-radius: 48px;
    bottom: 16px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    cursor: pointer;
    z-index: 1101;
    backdrop-filter: blur(8px);
    #vector-description{
      font-weight: 500 !important;
      padding: 0 0 0 36px;
      font-size: 16px;
    }
    #vector{
      width: 48px;
      height: 48px;
      margin-left: auto;
      margin-right: 32px;
    }
    #number{
      position: absolute;
      top: 0;
      right: 0;
      font-family: "Inter", sans-serif;
      border: 2px solid white;
      background-color: rgb(28, 116, 28);
      font-weight: 300;
      width: 20px;
      height: 20px;
      color: white;
      border-radius: 50%;
      text-align: center;
      line-height: 20px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    }
  }
  .vector-container:hover{
    -webkit-box-shadow:  0 3px 27px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 27px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 27px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  }
  .catPage-header-back{
    padding: 0 60px !important;
    margin: 0 0 !important;
    > i {
      font-size: 32px !important;
    }
    > h1 {
      font-size: 32px !important;
    }
  }
  .catPage-header-title{
      font-size: 40px !important;
  }
  .vector-container{
    height: 60px !important;
    #vector-description{
      font-size: 16px !important;
    }
    #vector{
      width: 32px !important;
      height: 32px !important;
      margin-right: 24px !important;
    }
  }
}

html{
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
}
body{
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
.blur{
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.039);
}
.dud-element{
  display: none;
}
.vector-container{
  position: fixed;
  background-color: rgba(255, 255, 255, 0.813);
  width: 40%;
  height: 80px;
  border-radius: 48px;
  bottom: 16px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
  box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  cursor: pointer;
  z-index: 1101;
  backdrop-filter: blur(8px);
  #vector-description{
    font-weight: 500;
    padding: 0 0 0 36px;
  }
  #vector{
    width: 48px;
    height: 48px;
    margin-left: auto;
    margin-right: 32px;
  }
  #number{
    position: absolute;
    top: 0;
    right: 0;
    font-family: "Inter", sans-serif;
    border: 2px solid white;
    background-color: rgb(28, 116, 28);
    font-weight: 300;
    width: 20px;
    height: 20px;
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  }
}
.vector-container:hover{
  -webkit-box-shadow:  0 3px 27px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  -moz-box-shadow:  0 3px 27px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
  box-shadow:  0 3px 27px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
}
/* --------- LOADING --------- */
.loading{
  width: 100vw;
  height: 100vh;
  position: fixed;
  backdrop-filter: blur(15px);
  background-color: rgba(255, 255, 255, 0.75);
  z-index: 1101;
}
.container {
  --uib-size: 200px;
  --uib-color: rgb(13, 85, 13);
  --uib-speed: 2.5s;
  position: absolute;
  right: 0;
  left: 0;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: var(--uib-size);
  width: var(--uib-size);
  margin-left: auto;
  margin-right: auto;
}

.slice {
  position: relative;
  height: calc(var(--uib-size) / 6);
  width: 100%;
  height: 100%;
}

.slice::before,
.slice::after {
  --uib-a: calc(var(--uib-speed) / -2);
  --uib-b: calc(var(--uib-speed) / -6);
  content: '';
  position: absolute;
  top: 0;
  left: calc(50% - var(--uib-size) / 12);
  height: 100%;
  width: calc(100% / 6);
  border-radius: 50%;
  background-color: var(--uib-color);
  flex-shrink: 0;
  animation: orbit var(--uib-speed) linear infinite;
  transition: background-color 0.3s ease;
}

.slice:nth-child(1)::after {
  animation-delay: var(--uib-a);
}

.slice:nth-child(2)::before {
  animation-delay: var(--uib-b);
}

.slice:nth-child(2)::after {
  animation-delay: calc(var(--uib-a) + var(--uib-b));
}

.slice:nth-child(3)::before {
  animation-delay: calc(var(--uib-b) * 2);
}
.slice:nth-child(3)::after {
  animation-delay: calc(var(--uib-a) + var(--uib-b) * 2);
}

.slice:nth-child(4)::before {
  animation-delay: calc(var(--uib-b) * 3);
}
.slice:nth-child(4)::after {
  animation-delay: calc(var(--uib-a) + var(--uib-b) * 3);
}

.slice:nth-child(5)::before {
  animation-delay: calc(var(--uib-b) * 4);
}
.slice:nth-child(5)::after {
  animation-delay: calc(var(--uib-a) + var(--uib-b) * 4);
}

.slice:nth-child(6)::before {
  animation-delay: calc(var(--uib-b) * 5);
}
.slice:nth-child(6)::after {
  animation-delay: calc(var(--uib-a) + var(--uib-b) * 5);
}

@keyframes orbit {
  0% {
    transform: translateX(calc(var(--uib-size) * 0.25)) scale(0.73684);
    opacity: 0.65;
  }
  5% {
    transform: translateX(calc(var(--uib-size) * 0.235)) scale(0.684208);
    opacity: 0.58;
  }
  10% {
    transform: translateX(calc(var(--uib-size) * 0.182)) scale(0.631576);
    opacity: 0.51;
  }
  15% {
    transform: translateX(calc(var(--uib-size) * 0.129)) scale(0.578944);
    opacity: 0.44;
  }
  20% {
    transform: translateX(calc(var(--uib-size) * 0.076)) scale(0.526312);
    opacity: 0.37;
  }
  25% {
    transform: translateX(0%) scale(0.47368);
    opacity: 0.3;
  }
  30% {
    transform: translateX(calc(var(--uib-size) * -0.076)) scale(0.526312);
    opacity: 0.37;
  }
  35% {
    transform: translateX(calc(var(--uib-size) * -0.129)) scale(0.578944);
    opacity: 0.44;
  }
  40% {
    transform: translateX(calc(var(--uib-size) * -0.182)) scale(0.631576);
    opacity: 0.51;
  }
  45% {
    transform: translateX(calc(var(--uib-size) * -0.235)) scale(0.684208);
    opacity: 0.58;
  }
  50% {
    transform: translateX(calc(var(--uib-size) * -0.25)) scale(0.73684);
    opacity: 0.65;
  }
  55% {
    transform: translateX(calc(var(--uib-size) * -0.235)) scale(0.789472);
    opacity: 0.72;
  }
  60% {
    transform: translateX(calc(var(--uib-size) * -0.182)) scale(0.842104);
    opacity: 0.79;
  }
  65% {
    transform: translateX(calc(var(--uib-size) * -0.129)) scale(0.894736);
    opacity: 0.86;
  }
  70% {
    transform: translateX(calc(var(--uib-size) * -0.076)) scale(0.947368);
    opacity: 0.93;
  }
  75% {
    transform: translateX(0%) scale(1);
    opacity: 1;
  }
  80% {
    transform: translateX(calc(var(--uib-size) * 0.076)) scale(0.947368);
    opacity: 0.93;
  }
  85% {
    transform: translateX(calc(var(--uib-size) * 0.129)) scale(0.894736);
    opacity: 0.86;
  }
  90% {
    transform: translateX(calc(var(--uib-size) * 0.182)) scale(0.842104);
    opacity: 0.79;
  }
  95% {
    transform: translateX(calc(var(--uib-size) * 0.235)) scale(0.789472);
    opacity: 0.72;
  }
  100% {
    transform: translateX(calc(var(--uib-size) * 0.25)) scale(0.73684);
    opacity: 0.65;
  }
}
/* --------------------------- */
.topNav{
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
width: 100%;
height: 80px;
background: #FFFFFF;
box-shadow: 0px 10px 50px rgba(139, 139, 139, 0.1);
/* -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
-moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2); */
  .topNav-logo{
    padding: 0 0 0 20px;
    width: 194px;
    cursor: pointer;
  }
  .topNav-links{
    display: flex;
    gap: 10%;
    position: absolute;
    left: 50%;
    right: 50%;
    justify-content: center;
    align-items: center;
    > a{
      padding: 0 48px;
      cursor: pointer;
      font-family: "Inter", sans-serif;
      text-decoration: none;
      white-space: nowrap;
      color: black;
      font-weight: 600;
      font-size: 16px;
      > i {
        display: none;
      }
    }
    > a:hover{
      text-decoration: underline;
      text-underline-position: under;
    }
  }
  .myAccount-link{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    margin-left: auto;
    display: flex;
    margin-right: 20px;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);

    > i{
      font-size: 24px;
      margin: auto;
      /* width: 24px;
      height: 24px; */
      text-decoration: none;
      color: black;
      /* border: 2px solid black;
      border-radius: 50%; */
    }
    > i:hover{
      color: #de8314;
    }
  }
  
  
}
.bottomNav{
  text-align: center;
  width: 100%;
  background-image: url("./images/bottomNav-img.webp");
  padding-bottom: 0;
  margin-bottom: 0;
  height: 100vh;
  object-fit: contain;
}
.app{
  height: 100%;
}
.home{

}
.search{
  position: relative;
  height: 688px;
  background-image: url("./images/search-img.webp");
  background-position: -1400px -700px;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: fit-content;
  padding: 80px 0;
  .search-title{
    margin: 0;
    color: white;
    font-size: 80px;
    font-family: "Inter", sans-serif;
    font-weight: 100;
    > span{
      font-weight: 400;
    }
  }
  .search-form{
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    .search-form-label{
      color: white;
      font-size: 40px;
      font-weight: 100;
      font-family: "Inter", sans-serif;
    }
    .search-form-input{
      width: 47.5%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 40px;
      margin-top: 20px;
      height: 64px;
      font-size: 20px;
      text-indent: 32px;
    }
  }
  .search-results-container{
    border: 1px solid rgb(0, 0, 0);
    width: 48%;
    max-height: 400px;
    border-radius: 0 0 20px 20px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: white;
    position: absolute;
    left: 0;
    right: 0; 
    top: 412px;
    
    .search-results:hover{
      background-color: gainsboro;
    }
    
    .search-results{
      display: flex;
      border-top: 1px solid black;
      text-decoration: none;
      color: black;
      font-weight: 100;
    
      .search-results-cirlce{
        width: 20px; 
        height: 20px; 
        border-radius: 50%; 
        border: 4px solid green;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
      .search-results-circle-filled{
        width: 20px; 
        height: 20px; 
        border-radius: 50%; 
        border: 4px solid green;
        background-color: goldenrod;
        cursor: pointer;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
        margin-bottom: auto;
      }
      .search-results-name{
        font-weight: 100;
        font-size: 20px;
        padding: 0 0 0 20px;
        font-family: "Inter", sans-serif;
      }
      .search-results-product{
        font-weight: 400;
        color: red;
        margin-left: auto;
        padding-right: 20px;
        font-family: "Inter", sans-serif;
      }
      .search-results-media{
        font-weight: 400;
        color: forestgreen;
        margin-left: auto;
        padding-right: 20px;
        font-family: "Inter", sans-serif;
      }
    }
  }
}
.categories{

  .categories-title{
    font-size: 64px;
    font-family: "Inter", sans-serif;
    font-weight: 600;
    text-align: center;
  }
  .categories-links{
    padding: 20px 20px;
    display: flex;
    text-decoration: none;
    justify-content: center;
    flex-wrap: wrap;
    .categories-link{
      text-decoration: none;
      padding: 0 12px;
      > img{
        width: 13vw;
        height: 13.5vw;
        border-radius: 12px;
        object-fit: cover;
      }
      > h2{
      text-decoration: none;
      color: black;
      font-weight: 600;
      font-family: "Inter", sans-serif;
      text-align: center;
      }
      .categories-link:hover{
        > img{
          
        }
      }
    }
    
  }

}
.catPage{
  font-family: "Inter", sans-serif;
  font-weight: 100;
  .catPage-header{
    .catPage-header-banner-air{
      background-image: url("./images/air-banner.webp");
      height: 232px;
      background-position: 0% 60%;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      > div > h1{
        color: white;
      }
      > div > i {
        color: white;
      }
      > h1{
        color: white;
      }
    }
    .catPage-header-banner-clean {
      position: relative; /* Ensure positioning context for pseudo-element */
      height: 232px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden; /* Hide overflow to prevent pseudo-element from being visible */
    }
    .catPage-header-banner-clean::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat:l no-repeat;
      background-image: url("./images/clean-banner.webp");      
      background-position: 0% 25%;
      background-size: 100vw;
      object-fit: cover;
      transform: scaleX(-1); /* Flip horizontally */
      z-index: -1; /* Ensure it's behind content */
  }
    .catPage-header-banner-emf{
      background-image: url("./images/emf-banner.webp");
      height: 232px;
      background-position: 5000% 40%;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      > div > h1{
        color: black;
      }
      > h1{
        color: black;
      }
      > div > i {
        color: black;
      }
    }
    .catPage-header-banner-water{
      background-image: url("./images/water-banner.webp");
      height: 232px;
      background-position: 0% 70%;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      > div > h1{
        color: black;
      }
      > h1{
        color: black;
      }
      > div > i {
        color: black;
      }
    }
    .catPage-header-banner-sauna{
      background-image: url("./images/sauna-banner.webp");
      height: 232px;
      background-position: 0% 50%;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .catPage-header-banner-redLight{
      background-image: url("./images/redLight-banner.webp");
      height: 232px;
      background-position: 0% 55%;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .catPage-header-banner-hbot{
      background-image: url("./images/hbot-banner.webp");
      height: 232px;
      background-position: 50% 50%;
      background-size: 120vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      div{
        i{
          color: black;
        }
        h1{
          color: black;
        }
      }
      h1{
        color: black;
      }
    }
    .catPage-header-banner-powerPlate{
      background-image: url("./images/powerPlate-banner.webp");
      height: 232px;
      background-position: 0% 86%;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
    .catPage-header-banner-siteAssets{
      background-image: url("./images/siteAssets-banner.webp");
      height: 232px;
      background-position: bottom;
      background-size: 100vw;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      div{
        i{
          color: black;
        }
        h1{
          color: black;
        }
      }
      h1{
        color: black;
      }
    }
    .catPage-header-back{
      cursor: pointer;
      padding-right: 6%;
      margin-left: 104px;
      display: flex;
      width: fit-content;
        > i{
          color: white;
          font-size: 48px;
          font-weight: 100;
          align-self: center;
        }
        > h1{
        color: white;
        font-family: "Inter", sans-serif;
        font-weight: 100;
        font-size: 48px;
        cursor: pointer;
        border-radius: 12px;
        }
      }
      .catPage-header-title{
        color: white;
        font-weight: 100;
        font-family: "Inter", sans-serif;
        font-size: 80px;
        padding-left: 104px;
        > span{
          font-weight: normal;
        }
      }
    .catPage-header-namesContainer{
      display: flex;

      >h1{
        font-weight: 100;
        color: rgba(0, 0, 0, 0.335);
        cursor: pointer;
      }
      >h1:hover{
        color: black;
      }
      #left{
        margin-left: auto;
      }
      #right{
        margin-right: auto;
      }
    }
    .catPage-header-names{
      cursor: pointer;
      display: flex;
      overflow-x: auto;
      max-width: 70%;
      white-space: nowrap;
      margin-left: auto;
      margin-right: auto;

      > h1{
        font-weight: 300;
      }
      .catPage-header-names:first-child{
        margin-left: auto;
      }
      .catPage-header-names:last-child{
        margin-right: auto;
      }
      .catPage-header-names-name{
        font-weight: 600;
        font-family: "Inter", sans-serif;
        padding: 12px 32px;
        color: rgba(0, 0, 0, 0.335);
      }
      .catPage-header-names-name:hover{
        text-decoration: underline;
        text-underline-position: under;
      }
      .catPage-header-names-name-selected{
        font-weight: 600;
        text-decoration: underline;
        text-underline-position: under;
        color: black;
        font-family: "Inter", sans-serif;
        padding: 12px 32px;
      }
    }
  }
  .catPage-component-container{
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px;
  }
}
.viewAll-button{
  text-decoration: none;
  color: #336A3E;
  border: 4px solid #336A3E;
  border-radius: 12px;
  width: fit-content;
  padding: 8px 16px;
  border: 20px 0px;
  font-weight: 400;
}
.viewAll-button:hover{
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.424);
}
.catPageImages {
  font-weight: 100;
  padding-bottom: 48px;

  > h1{
    font-weight: 400;
    border-bottom: 1.5px solid rgb(119, 119, 119);
  }
  > a{
    position: absolute;
  }
  .catPageImages-thumbnail-container{
    /* display: flex;
    flex-wrap: wrap;
    overflow: hidden; */
    font-weight: 100;
    padding: 0 0 0 0;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    grid-template-rows: auto auto;
    grid-auto-rows: 0;
    justify-content: start;
    justify-items: center;
    overflow: hidden;
  }
  #thumbnail{
    width: 120px;
    border-radius: 16px;

    #thumbnail-img{
      width: 120px;
      margin: 0 auto;
      aspect-ratio: 1/1;
      object-fit: cover;
      border-radius: 16px;
    }
  }
  .catPageImages-thumbnail-selected{
    width: 120px;
    text-align: center;
    font-weight: 100;
    position: relative;
    border: 4px solid rgb(6, 90, 6);
    border-radius: 12px;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    padding: 4px;
    margin: 12px 0;
    cursor: pointer;
    
    #select-circle{
      width: 16px;
      height: 16px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
    }
    #select-circle-clicked{
      width: 16px;
      height: 16px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;

      > div{
        border-radius: 50%;
      }
      #circle-border {
        width: 82%;
        height: 82%;
        background-color: rgb(11, 93, 11);
        border-radius:  50% !important;
        border: solid white 2px;
      }
    }
    .thumbnail-preview{
      width: 80px;
      margin: 0 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
      border-radius: 4px;
      opacity: 20%;
    }
    .catPageImages-thumbnail-image{
      width: 80px;
      margin: 0 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
      border-radius: 4px;
    }
    #pdf {
      font-size: 96px;
      color: rgb(11, 93, 11);
      display: flex;
      justify-content: center;
    }
    #openPreview{
      position: absolute;
      width: 100%;
      top: 30%;
      left: 0;
      right: 0;
      text-decoration: underline;
      color: black;
      font-weight: 500;
      text-align: center;
    }
    .catPageImages-thumbnail-name{
      font-weight: 100;
      overflow-wrap: break-word;
    }
  }
  .catPageImages-thumbnail{
    width: 120px;
    text-align: center;
    font-weight: 100;
    position: relative;
    border: 1px solid gray;
    border-radius: 12px;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    padding: 4px;
    margin: 12px 0;
    cursor: pointer;

    #select-circle{
      width: 16px;
      height: 16px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
    }
    #select-circle-clicked{
      width: 16px;
      height: 16px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
    }
    .thumbnail-preview{
      width: 80px;
      margin: 0 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
      border-radius: 4px;
      opacity: 20%;
    }
    #pdf{
      font-size: 96px;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      color: rgb(11, 93, 11);
    }
    .catPageImages-thumbnail-image{
      width: 80px;
      margin: 0 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
      border-radius: 4px;
    }
    #openPreview{
      position: absolute;
      width: 100%;
      top: 30%;
      left: 0;
      right: 0;
      text-decoration: underline;
      color: black;
      font-weight: 500;
      text-align: center;
    }
    .catPageImages-thumbnail-name{
      font-weight: 100;
      overflow-wrap: break-word;
    }
  }
}
.catPageArticles{
  display: flex;
  flex-direction: column;
  font-weight: 100;
  padding-bottom: 48px;
  
  
  > h1{
    font-weight: 400;
    border-bottom: 1.5px solid rgb(119, 119, 119);
  }
  .catPageArticles-thumbnail-container{
    font-weight: 100;
    padding: 0 0 0 0;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 150px);
    grid-template-rows: auto auto;
    grid-auto-rows: 0;
    justify-content: start;
    justify-items: center;
    overflow: hidden;
  }
  /* .catPageArticles-thumbnail:nth-child(n + 3){
    display: none;
  } */

  .catPageArticles-thumbnail{
    width: 120px;
    text-align: center;
    font-weight: 100;
    position: relative;
    border: 1px solid gray;
    border-radius: 12px;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    padding: 4px;
    margin: 12px 0;
    cursor: pointer;

    .catPageArticles-thumbnail-circle{
      width: 16px;
      height: 16px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
    }
    .catPageArticles-thumbnail-circle-filled{
      width: 16px;
      height: 16px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
    }
    #circle-border{
      width: 76%;
      height: 76%;
      background-color: rgb(11, 93, 11);
      border-radius: 50%;
      border: solid white 4px;
    }
    #pdf{
      grid-column: 2/3;
      grid-row: 1/4;
      font-size: 96px;
      margin-left: auto;
      margin-right: auto;
      margin-top: auto;
      margin-bottom: auto;
      color: rgb(11, 93, 11);
    }
    .catPageArticles-thumbnail-name{
      grid-column: 3/4;
      grid-row: 1/2;
      margin-top: auto;
      margin-bottom: auto;
      font-weight: 100;
    }
    .catPageArticles-thumbnail-description{
      grid-column: 3/4;
      grid-row: 2/3;
      margin-top: auto;
      margin-bottom: auto;
      font-weight: 100;
    }
    .catPageArticles-thumbnail-link{
      grid-column: 3/4;
      grid-row: 3/4;
      margin-top: auto;
      margin-bottom: auto;
      font-weight: 100;
    }
  }
}
.catPageVideos{
  font-weight: 100;
  padding-bottom: 48px;

  > a{
    position: absolute;
  }
  > h1{
    font-weight: 400;
    border-bottom: 1.5px solid rgb(119, 119, 119);
  }
  .catPageVideos-container:nth-child(n + 4){
    display: none;
  }
  .catPageVideos-container{
    display: grid;
    font-weight: 100;
    overflow: hidden;
    grid-template-columns: repeat(3, 23vw);
    justify-content:space-around;
    max-width: 1200px;
    height: 100%;

    .catPageVideos-thumbnail{
      padding-right: 20px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      height: 100%;
      width: 100%;

      > iframe{
        
      }

      .catPageVideos-thumbnail-name{
        text-align: center;
        font-weight: 100;
      }
    }
    #select-circle{
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: 4px forestgreen solid;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      z-index: 100;
      left: 0;
    }
    #select-circle-clicked{
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: 4px forestgreen solid;
      border-radius: 50%;
      position: absolute;
      z-index: 100;
      left: 0;
    }
    #circle-border{
      width: 76%;
      height: 76%;
      background-color: rgb(11, 93, 11);
      border-radius: 50%;
      border: solid white 4px;
    }
    .reactPlayer{
      width: 23vw;
      height: 12.5vw; 
      max-width: 384px;
      padding: 0px;
      border-radius: 20px;
      cursor: pointer;
    }
  }
}
iframe{
  cursor: pointer;
}
.catPageSocials{
  font-weight: 100;
  padding-bottom: 48px;

  >a{
    position: absolute;
  }
  > h1{
    font-weight: 400;
    border-bottom: 1.5px solid rgb(119, 119, 119);
  }
  .catPageSocials-container{
    display: grid;
    padding: 32px 0 0 0;
    margin-bottom: 20px;
    grid-template-columns: repeat(auto-fit, 152px);
    grid-template-rows: auto auto;
    grid-auto-rows: 0px;
    justify-content: start;
    justify-items: center;
    overflow: hidden;

    #thumbnail{
      width: 120px;
      border-radius: 16px;
  
      #thumbnail-img{
        width: 120px;
        margin: 0 auto;
        aspect-ratio: 1/1;
        object-fit: cover;
        border-radius: 16px;
      }
    }
    .catPageSocials-thumbnail-row1{
      grid-row: 1/2;
      width: 104px;
      text-align: center;
      margin: 0 12px 0 0;
      position: relative;
      border: 1px solid gray;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      padding: 4px;
      margin: 4px;
      cursor: pointer;

      #select-circle{
        width: 16px;
        height: 16px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        background-color: white;
        cursor: pointer;
        z-index: 100;
      }
      #select-circle-clicked{
        width: 16px;
        height: 16px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        z-index: 100;
      }

      .catPageSocials-thumbnail-image-11{
        width: 80px;
        height: 80px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        cursor: pointer;
      }
      .catPageSocials-thumbnail-image-11-preview{
        width: 80px;
        height: 80px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        opacity: 20%;
        cursor: pointer;
      }
      #openPreview{
        position: absolute;
        width: 100%;
        top: 30%;
        left: 0;
        right: 0;
        text-decoration: underline;
        color: black;
        font-weight: 500;
        text-align: center;
      }
      .catPageSocials-thumbnail-name{
        text-align: center;
        font-weight: 100;
        overflow-wrap: break-word;
      }
    }
    .catPageSocials-thumbnail-row1-selected{
      grid-row: 1/2;
      width: 104px;
      text-align: center;
      margin: 0 12px 0 0;
      position: relative;
      border: 4px solid rgb(6, 90, 6);
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      padding: 4px;
      margin: 0px;
      cursor: pointer;

      #select-circle{
        width: 16px;
        height: 16px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        background-color: white;
        cursor: pointer;
        z-index: 100;
      }
      #select-circle-clicked{
        width: 16px;
        height: 16px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
        z-index: 100;
      }
      #circle-border{
        width: 83%;
        height: 83%;
        background-color: rgb(11, 93, 11);
        border-radius: 50%;
        border: solid white 2px;
      }
      .catPageSocials-thumbnail-image-11{
        width: 80px;
        height: 80px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        cursor: pointer;
      }
      .catPageSocials-thumbnail-image-11-preview{
        width: 80px;
        height: 80px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        opacity: 20%;
        cursor: pointer;
      }
      #openPreview{
        position: absolute;
        width: 100%;
        top: 30%;
        left: 0;
        right: 0;
        text-decoration: underline;
        color: black;
        font-weight: 500;
        text-align: center;
      }
      .catPageSocials-thumbnail-name{
        text-align: center;
        font-weight: 100;
        overflow-wrap: break-word;
      }
    }
    .catPageSocials-thumbnail-row2-selected{
      grid-row: 2/3;
      width: 104px;
      text-align: center;
      margin: 0 12px 0 0;
      position: relative;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      padding: 4px;
      margin: 0px;
      border: 4px solid rgb(6, 90, 6);

      #select-circle{
        width: 12px;
        height: 12px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        background-color: white;
        cursor: pointer;
      }
      #select-circle-clicked{
        width: 12px;
        height: 12px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
      }
      #circle-border{
        width: 83%;
        height: 83%;
        background-color: rgb(11, 93, 11);
        border-radius: 50%;
        border: solid white 2px;
      }
      .catPageSocials-thumbnail-image-916{
        width: 80px;
        height: 144px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        cursor: pointer;
      }
      .catPageSocials-thumbnail-image-916-preview{
        width: 80px;
        height: 144px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        opacity: 20%;
        cursor: pointer;
      }
      #openPreview{
        position: absolute;
        width: 100%;
        top: 30%;
        left: 0;
        right: 0;
        text-decoration: underline;
        color: black;
        font-weight: 500;
        text-align: center;
      }
      .catPageSocials-thumbnail-name{
        text-align: center;
        font-weight: 100;
        overflow-wrap: break-word;
      }
    }
    .catPageSocials-thumbnail-row2{
      grid-row: 2/3;
      width: 104px;
      text-align: center;
      margin: 0 12px 0 0;
      position: relative;
      border: 1px solid gray;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      padding: 4px;
      margin: 4px;
      border: 4px solid rgb(6, 90, 6);

      #select-circle{
        width: 12px;
        height: 12px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        background-color: white;
        cursor: pointer;
      }
      #select-circle-clicked{
        width: 12px;
        height: 12px;
        border: solid forestgreen 4px;
        border-radius: 50%;
        position: absolute;
        cursor: pointer;
      }

      .catPageSocials-thumbnail-image-916{
        width: 80px;
        height: 144px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        cursor: pointer;
      }
      .catPageSocials-thumbnail-image-916-preview{
        width: 80px;
        height: 144px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        border-radius: 4px;
        opacity: 20%;
        cursor: pointer;
      }
      #openPreview{
        position: absolute;
        width: 100%;
        top: 30%;
        left: 0;
        right: 0;
        text-decoration: underline;
        color: black;
        font-weight: 500;
        text-align: center;
      }
      .catPageSocials-thumbnail-name{
        text-align: center;
        font-weight: 100;
        overflow-wrap: break-word;
      }
    }
  }
}
.back-container{
  cursor: pointer;
  padding-right: 6%;
  display: flex;
  width: fit-content;
    > i{
      color: black;
      font-size: 48px;
      font-weight: 100;
      align-self: center;
    }
    > h1{
    color: black;
    font-family: "Inter", sans-serif;
    font-weight: 100;
    font-size: 48px;
    cursor: pointer;
    border-radius: 12px;
    }
  }
.all-title{
  font-weight: 100;
}
.allImages{
  padding: 0 104px;
  font-weight: 100;
  font-family: "Inter", sans-serif;

  .all-container{
    display: flex;
    height: auto;
    flex-wrap: wrap;
  }

  .catPageImages-thumbnail{
    text-align: center;
    padding-right: 20px;
    position: relative;

    #select-circle{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
    }
    #select-circle-clicked{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
    }
    .thumbnail-preview{
      max-width: 400px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
      border-radius: 4px;
      opacity: 20%;
    }
    > img{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    }
    .preview-container{
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 100%;
      top: 30%;
      left: 0;
      text-align: center;
    }
    #selectItem{
      font-weight: 600;
      font-size: 16px;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex !important;
    }
    #selectItem:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      display: inline-flex !important;  
      cursor: pointer;
    }
    #openPreview{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex;
    }
    #openPreview:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
    }
    .catPageImages-thumbnail-name{
      font-weight: 100;
      max-width: 400px;
      overflow-wrap: break-word;
    }
  }
  .catPageImages-thumbnail-selected{
    text-align: center;
    padding-right: 20px;
    position: relative;

    #select-circle{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
    }
    #select-circle-clicked{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;

      #circle-border {
        width: 76%;
        height: 76%;
        background-color: rgb(11, 93, 11);
        border-radius: 50%;
        border: solid white 4px;
      }
    }
    .catPageImages-thumbnail-image{
      border: 4px solid rgb(11, 93, 11);
      border-radius: 24px;
    }
    .thumbnail-preview{
      max-width: 400px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
      border-radius: 4px;
      opacity: 20%;
      border: 4px solid rgb(11, 93, 11);
      border-radius: 24px;
    }
    > img{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    }
    .preview-container{
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 100%;
      top: 30%;
      left: 0;
      text-align: center;
    }
    #selectItem{
      font-weight: 600;
      font-size: 16px;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex !important;
    }
    #selectItem:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      display: inline-flex !important;  
      cursor: pointer;
    }
    #openPreview{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex;
    }
    #openPreview:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
    }
    .catPageImages-thumbnail-name{
      font-weight: 100;
      max-width: 400px;
      overflow-wrap: break-word;
    }
  }
}
.allArticles{
  padding: 0 104px;
  font-weight: 100;
  font-family: "Inter", sans-serif;

  .all-container{
    display: flex;
    height: auto;
    flex-wrap: wrap;
    
    .allArticles-thumbnail{
      text-align: center;
      width: 320px;
      padding-right: 32px;
      cursor: pointer;
      position: relative;

      #select-circle{
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 4px forestgreen solid;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        z-index: 100;
      }
      #select-circle-clicked{
        cursor: pointer;
        width: 32px;
        height: 32px;
        border: 4px forestgreen solid;
        border-radius: 50%;
        position: absolute;
        z-index: 100;

        #circle-border {
          width: 76%;
          height: 76%;
          background-color: rgb(11, 93, 11);
          border-radius: 50% !important;
          border: solid white 4px;
        }
      }
      .allArticles-thumbnail-iframe{
        height: 400px;
        cursor: pointer;
        border-radius: 12px;
      }
      .allArticles-thumbnail-iframe-preview{
        height: 400px;
        opacity: 20%;
        cursor: pointer;
      }
      .preview-container{
        position: absolute;
        display: inline-block;
        height: 100%;
        width: 100%;
        top: 30%;
        left: 0;
        text-align: center;
        left: -15px;
      }
      #selectItem{
        font-weight: 600;
        font-size: 16px;
        color: black;
        padding: 8px;
        margin-left: 8px;
        border: 2px solid black;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.76);
        text-decoration: none;
        width: fit-content;
        height: fit-content;
        z-index: 1100;
        display: inline-flex !important;
      }
      #selectItem:hover{
        font-size: 16px;
        font-weight: 600;
        color: black;
        padding: 8px;
        margin-left: 8px;
        border: 2px solid black;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.76);
        text-decoration: none;
        width: fit-content;
        height: fit-content;
        z-index: 1100;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        display: inline-flex !important;  
        cursor: pointer;
      }
      #openPreview{
        font-size: 16px;
        font-weight: 600;
        color: black;
        padding: 8px;
        margin-right: 8px;
        border: 2px solid black;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.76);
        text-decoration: none;
        width: fit-content;
        height: fit-content;
        z-index: 1100;
        display: inline-flex;
      }
      #openPreview:hover{
        font-size: 16px;
        font-weight: 600;
        color: black;
        padding: 8px;
        margin-right: 8px;
        border: 2px solid black;
        border-radius: 16px;
        background-color: rgba(255, 255, 255, 0.76);
        text-decoration: none;
        width: fit-content;
        height: fit-content;
        z-index: 1100;
        -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
        box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
        cursor: pointer;
      }
      .allArticles-thumbnail-name{
        font-weight: 100;
        margin-top: 2px;
        overflow-wrap: break-word;
      }
      .allArticles-thumbnail-description{
        font-weight: 100;
        overflow-wrap: break-word;
      }
    }
  }
}
.allVideos{
  padding: 0 104px;
  font-weight: 100;
  font-family: "Inter", sans-serif;

  .all-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 8px;

    .catPageVideos-thumbnail{
      padding-bottom: 20px;

      .catPageVideos-thumbnail-name{
        text-align: center;
      }
    }

    #select-circle{
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: 4px forestgreen solid;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      z-index: 100;
    }
    #select-circle-clicked{
      cursor: pointer;
      width: 32px;
      height: 32px;
      border: 4px forestgreen solid;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      z-index: 100;
    }
    #circle-border {
      width: 76%;
      height: 76%;
      background-color: rgb(11, 93, 11);
      border-radius: 50%;
      border: solid white 4px;
    }
    .reactPlayer{
      width: 30vw;
      height: 20vw;
      padding: 0px;
      border-radius: 24px;
    }
  }

  #select-circle{
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 4px forestgreen solid;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    z-index: 100;
  }
  #select-circle-clicked{
    cursor: pointer;
    width: 32px;
    height: 32px;
    border: 4px forestgreen solid;
    border-radius: 50%;
    position: absolute;
    z-index: 100;
  }
  .reactPlayer{
    width: 464px;
    height: 336px;
    padding: 0px;
  }
}
.allSocials{
  padding: 0 104px;
  font-weight: 100;
  font-family: "Inter", sans-serif;

  .all-container{
    display: flex;
    height: auto;
    flex-wrap: wrap;
  }
  .catPageSocials-thumbnail-row1{
    text-align: center;
    padding-right: 20px;
    position: relative;

    #select-circle{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
      z-index: 100;
    }
    #select-circle-clicked{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      z-index: 100;
    }

    .catPageSocials-thumbnail-image-11{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    }
    .catPageSocials-thumbnail-image-11-preview{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      opacity: 20%;
      cursor: pointer;
    }
    .catPageSocials-thumbnail-name{
      font-weight: 100;
      max-width: 400px;
      overflow-wrap: break-word;
    }
    .preview-container{
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 100%;
      top: 30%;
      left: 0;
      text-align: center;
    }
    #selectItem{
      font-weight: 600;
      font-size: 16px;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex !important;
    }
    #selectItem:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      display: inline-flex !important;  
      cursor: pointer;
    }
    #openPreview{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex;
    }
    #openPreview:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
    }
  }
  .catPageSocials-thumbnail-row1-selected{
    text-align: center;
    padding-right: 20px;
    position: relative;

    #select-circle{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
      z-index: 100;
    }
    #select-circle-clicked{
      width: 32px;
      height: 32px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      z-index: 100;

      #circle-border {
        width: 76%;
        height: 76%;
        background-color: rgb(11, 93, 11);
        border-radius: 50% !important;
        border: solid white 4px;
      }
    }

    .catPageSocials-thumbnail-image-11{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      border: 4px solid rgb(11, 93, 11);
    }
    .catPageSocials-thumbnail-image-11-preview{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      opacity: 20%;
      cursor: pointer;
      border: 4px solid rgb(11, 93, 11);
    }
    .catPageSocials-thumbnail-name{
      font-weight: 100;
      max-width: 400px;
      overflow-wrap: break-word;
    }
    .preview-container{
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 100%;
      top: 30%;
      left: 0;
      text-align: center;
    }
    #selectItem{
      font-weight: 600;
      font-size: 16px;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex !important;
    }
    #selectItem:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      display: inline-flex !important;  
      cursor: pointer;
    }
    #openPreview{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex;
    }
    #openPreview:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
    }
  }
  .catPageSocials-thumbnail-row2{
    text-align: center;
    padding-right: 20px;
    position: relative;

    #select-circle{
      width: 20px;
      height: 20px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
      z-index: 100;
    }
    #select-circle-clicked{
      width: 20px;
      height: 20px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      z-index: 100;
    }

    .catPageSocials-thumbnail-image-916{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    }
    .catPageSocials-thumbnail-image-916-preview{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      opacity: 20%;
      cursor: pointer;
    }
    .catPageSocials-thumbnail-name{
      font-weight: 100;
      max-width: 400px;
      overflow-wrap: break-word;
    }
    .preview-container{
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 100%;
      top: 30%;
      left: 0;
      text-align: center;
    }
    #selectItem{
      font-weight: 600;
      font-size: 16px;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex !important;
    }
    #selectItem:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      display: inline-flex !important;  
      cursor: pointer;
    }
    #openPreview{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex;
    }
    #openPreview:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
    }
  }
  .catPageSocials-thumbnail-row2-selected{
    text-align: center;
    padding-right: 20px;
    position: relative;

    #select-circle{
      width: 20px;
      height: 20px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      background-color: white;
      cursor: pointer;
      z-index: 100;
    }
    #select-circle-clicked{
      width: 20px;
      height: 20px;
      border: solid forestgreen 4px;
      border-radius: 50%;
      position: absolute;
      cursor: pointer;
      z-index: 100;

      #circle-border {
        width: 76%;
        height: 76%;
        background-color: rgb(11, 93, 11);
        border-radius: 50% !important;
        border: solid white 4px;
      }
    }

    .catPageSocials-thumbnail-image-916{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      border: 4px solid rgb(11, 93, 11);
    }
    .catPageSocials-thumbnail-image-916-preview{
      max-width: 400px;
      border-radius: 12px;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      opacity: 20%;
      cursor: pointer;
      border: 4px solid rgb(11, 93, 11);

    }
    .catPageSocials-thumbnail-name{
      font-weight: 100;
      max-width: 400px;
      overflow-wrap: break-word;
    }
    .preview-container{
      position: absolute;
      display: inline-block;
      height: 100%;
      width: 100%;
      top: 30%;
      left: 0;
      text-align: center;
    }
    #selectItem{
      font-weight: 600;
      font-size: 16px;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex !important;
    }
    #selectItem:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-left: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      display: inline-flex !important;  
      cursor: pointer;
    }
    #openPreview{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      display: inline-flex;
    }
    #openPreview:hover{
      font-size: 16px;
      font-weight: 600;
      color: black;
      padding: 8px;
      margin-right: 8px;
      border: 2px solid black;
      border-radius: 16px;
      background-color: rgba(255, 255, 255, 0.76);
      text-decoration: none;
      width: fit-content;
      height: fit-content;
      z-index: 1100;
      -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
      box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
      cursor: pointer;
    }
  }
}
.download{
  position: fixed;
  margin: auto;
  /* width: 40vw;
  height: 30vw; */
  background-color: white;
  -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
  box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
  text-align: center;
  align-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: white;
  z-index: 1100;
  overflow-y: scroll;
  color: rgb(71, 71, 71);
  backdrop-filter: blur(5px);
  background-color: rgba(111, 111, 111, 0.685);
  
  .download-box{
    width: 40vw;
    min-width: 384px;
    max-height: 70vh;
    background-color: white;
    border-radius: 40px;
    -webkit-box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    -moz-box-shadow:  0 3px 7px rgba(0,0,0,.2),  0 -3px 7px rgba(0,0,0,.2);
    box-shadow:  0 3px 7px rgba(0, 0, 0, .2),  0 -3px 7px rgba(0, 0, 0, .2);
    text-align: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: scroll;
  }

  .download-x{
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    position: absolute;
    display: inline;
    padding: 0 4px;
    font-size: 16px;
    border-radius: 6px;
    right: 8px;
    top: 0px;
    color: black;
    border: 1.5px solid black;
  }
  .download-x:hover{
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.424);
  }
  .download-alert{
    padding: 20px;

    > h2{
      font-weight: 100;
      font-family: "Inter", sans-serif;
    }

    .download-alert-options{
      display: inline-flex;
      
      > h2{
        padding: 4px 12px;
        margin: 0 20px;
        border-radius: 12px;
        cursor: pointer;
        font-weight: 100;
        font-family: "Inter", sans-serif;
        border: 2px solid rgb(71, 71, 71);
      }
      > h2:hover{
        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.424);
      }
    }
  }
  .download-edit{
    padding: 20px;
    
    > h2{
      font-family: "Inter", sans-serif;
      font-weight: 100;
    }
    > h3{
      font-family: "Inter", sans-serif;
      font-weight: 100;
      cursor: pointer;
      border: 2px solid black;
      border-radius: 12px;
      display: inline;
      padding: 4px 12px;
      margin: 0 12px;
    }
    > h3:hover{
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.424);
    }
    > ul{
      font-family: "Inter", sans-serif;
      font-weight: 100;
      list-style-type: none;
      padding: 0;

      .download-mappedMedia{
        font-family: "Inter", sans-serif;
        border: 1px solid black;
        margin: 12px 0;
        padding: 4px 0;
        border-radius: 16px;
        background-color: white;

        > li{
          font-family: "Inter", sans-serif;
          font-weight: 300;
          text-decoration: none;
        }
        > h3{
          font-family: "Inter", sans-serif;
          font-weight: 100;
          padding: 4px 8px;
          border: 2px solid black;
          cursor: pointer;
          border-radius: 6px;
          width: 72px;
          text-align: center;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: 4px;
        }
         > h3:hover{
          box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.424);
        }
      }
    }
  }
}
